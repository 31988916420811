import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";

import "../components/main.css";

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <BackgroundImage
      Tag="section"
      fluid={data.fourOhFourHeader.childImageSharp.fluid}
    >
      <div id="four-oh-four" className="color-white large center">
        <h1 id="sorry" className="ridiculous">Sorry</h1>
        <p className="poppins">Looks like something went wrong on our end.<br />Head back to the A Church Building homepage.</p>
        <Link className="button background-yellow poppins color-white" to="/">GO BACK</Link>
      </div>
    </BackgroundImage>
  </Layout>
)

export const query = graphql`
  query {
    fourOhFourHeader: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fluid(maxHeight: 822, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default NotFoundPage
